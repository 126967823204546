import React from 'react'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'
import {
  MainHolder,
  MainTextHolder,
  Loading,
  SubPageHero,
  mailTo,
} from '../components/Utils'
import { ApiStore } from '../stores/apiStore'
import { observer } from 'mobx-react'
import { AnimatePresence, motion } from 'framer-motion'

import water_services from '../img/water_services.jpg'

export const Rental = observer(({ api }: { api: ApiStore }) => {
  const pageData = api.getPageData('rental')

  pageData?.categories?.map(category => {
    new Image().src = category.image
  })

  return (
    <>
      <AnimatePresence>
        {!api.isLoading.rental && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <MediaQuery minDeviceWidth={769}>
              <SubPageHero
                bgImage={water_services}
                title={pageData?.title}
                color={pageData?.headercolor}
              >
                {pageData?.content}
              </SubPageHero>
            </MediaQuery>
            <MainHolder>
              <MediaQuery maxDeviceWidth={769}>
                <MainTextHolder>
                  <h1
                    style={{
                      textTransform: 'uppercase',
                      fontSize: '19px',
                      marginTop: '20px',
                    }}
                  >
                    {pageData?.title}
                  </h1>
                  <div
                    dangerouslySetInnerHTML={{ __html: pageData?.content }}
                  />
                </MainTextHolder>
              </MediaQuery>
              <MainTextHolder>
                <CategoryHolder>
                  {pageData?.categories?.map(
                    (category, index) =>
                      category.parent.length === 0 && (
                        <Category
                          key={index}
                          background={category.image}
                          onClick={() => mailTo(category.title)}
                        >
                          <CategoryOverlay />
                          <span style={{ zIndex: 1 }}>{category.title}</span>
                        </Category>
                      )
                  )}
                </CategoryHolder>
              </MainTextHolder>
            </MainHolder>
          </motion.div>
        )}
        {api.isLoading.main && <Loading />}
      </AnimatePresence>
    </>
  )
})

const CategoryHolder = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 769px) {
    max-width: 980px;
    flex-direction: row;
    flex-flow: wrap;
    margin-bottom: 80px;
  }
`

const Category = styled.li`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props: any) =>
    'url(' + props.background + ')' || 'none'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  color: ${(props: any) => props.color || '#000'};
  text-shadow: ${(props: any) =>
    props.color === '#fff' ? '-1px 3px 4px rgba(0,0,0,0.50)' : ''};
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 768px) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  > span {
    font-weight: inherit;
  }

  @media (min-width: 769px) {
    width: 46%;
    margin: 2%;
    cursor: pointer;
  }
`

const CategoryOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
`
