import styled from 'styled-components'
import React from 'react'
import { observer } from 'mobx-react'
import MediaQuery from 'react-responsive'

import MenuItems from '../components/MenuItems'
import { UIStore } from '../stores/UIStore'

export const MobileMenu = observer(() => {
  return (
    <MediaQuery maxDeviceWidth={768}>
      <MenuHolder open={UIStore.menuOpen}>
        <MenuItems />
      </MenuHolder>
    </MediaQuery>
  )
})

export const MenuIcon = observer(() => {
  return (
    <MediaQuery maxDeviceWidth={768}>
      <Icon
        onClick={() => {
          UIStore.setMenuOpen(!UIStore.menuOpen)
        }}
        open={UIStore.menuOpen}
      >
        <ul>
          <li></li>
          <li></li>
        </ul>
      </Icon>
    </MediaQuery>
  )
})

const MenuHolder = styled.div`
  position: absolute;
  top: 60px;
  left: ${(props: any) => (props.open ? '0' : '100vw')};
  z-index: 5;
  padding: 40px 22px 0;
  min-width: 100vw;
  min-height: 100vh;
  /*background-image: linear-gradient(330deg, rgba(0, 0, 0, 0), #172733);*/
  background-color: rgba(23, 39, 51, 0.9);
  transition: left 0.2s ease-in-out;

  @media (min-width: 769px) {
    top: 80px;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`

const Icon = styled.a`
  position: absolute;
  top: 20px;
  right: 44px;
  display: block;
  transition: all 50ms ease;

  ul {
    width: 24px;
    height: 30px;
    list-style: none;
    padding: 0;
    position: absolute;
    transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1),
      color 1s cubic-bezier(0.23, 1, 0.32, 1);
    transform: translateZ(0);
    color: #fff;

    li {
      width: 100%;
      height: 3px;
      background-color: #000;
      position: absolute;
      margin-top: 5px;
      transform: ${(props: any) =>
        props.open
          ? 'rotate(45deg) translateZ(0);'
          : 'translateY(-3.75px) translateZ(0)'};
      transition: transform 1s cubic-bezier(0.23, 1, 0.32, 1),
        background-color 1s cubic-bezier(0.23, 1, 0.32, 1);

      &:last-child {
        margin-top: 6px;
        transform: ${(props: any) =>
          props.open
            ? 'rotate(-45deg) translateZ(0)'
            : 'translateY(3.75px) translateZ(0)'};
      }
    }
  }
`
