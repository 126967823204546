import { Link } from 'react-router-dom'
import styled from 'styled-components'
import React from 'react'
import MediaQuery from 'react-responsive'

import { MobileMenu, MenuIcon } from './MobileMenu'
import Menu from './Menu'
import { ReactComponent as Logo } from '../img/logo.svg'
import { motion } from 'framer-motion'
import { handleNavigation } from './Utils'

function Header() {
  return (
    <HeaderContainer
      animate={{ top: 0 }}
      transition={{ duration: 1, delay: 1 }}
    >
      <LogoHolder>
        <HeaderLogo>
          <Link to="/" onClick={() => handleNavigation('main')}>
            <Logo />
          </Link>
        </HeaderLogo>
      </LogoHolder>
      <MenuIcon />
      <MobileMenu />
      <DesktopMenuHolder>
        <MediaQuery minDeviceWidth={769}>
          <Menu />
        </MediaQuery>
      </DesktopMenuHolder>
    </HeaderContainer>
  )
}

const HeaderContainer = styled(motion.div)`
  transition: top 0.5s ease-in-out;
  background-color: rgba(255, 255, 255, 0.95);
  width: 100vw;
  position: fixed;
  z-index: 10;
  display: flex;
  top: -60px;
  height: 60px;

  @media (min-width: 769px) {
    max-width: 980px;
    margin: 0 auto;
    position: relative;
    flex-direction: column;
    height: auto;
    top: 0;
  }
`

const LogoHolder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;

  @media (min-width: 769px) {
    position: relative;
    width: auto;
  }
`

const HeaderLogo = styled.div`
  text-align: center;
  margin-top: 11px;
  margin-left: 8px;

  @media (min-width: 769px) {
    margin: 0;
    margin-top: 16px;
  }

  svg {
    cursor: pointer;
    height: 40px;

    @media (min-width: 769px) {
      height: 56px;
    }
  }
`

const DesktopMenuHolder = styled.div`
  width: 100vw;

  @media (min-width: 769px) {
    position: relative;
    width: 980px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
  }
`

export default Header
