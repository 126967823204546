import { action, observable } from 'mobx'

import { api } from '../components/Utils'
import { pages } from '../variables'

export interface IPageData {
  pageName: string
  pageData: {
    title: string
    content: any
    ingress?: any
    image?: string
    headercolor?: string
    puffar?: [
      {
        title: string
        link: string
        color: string
        image: string
        shadow: boolean
      }
    ]
    staff?: [
      {
        id: number
        title: string
        email: string
        phone: string
        image: string
      }
    ]
    projects?: [
      {
        id: number
        title: string
        content: string
        image: string
      }
    ]
    categories?: [
      {
        title: string
        content: string
        image: string
        parent: []
      }
    ]
    sections?: [
      {
        title: string
        content: string
        image: string
        imageposition: string
        achor: string
      }
    ]
  }
}

export class ApiStore {
  @observable isLoading = {
    main: true,
    about: true,
    projects: true,
    rental: true,
    services: true,
    work: true,
  }
  @observable pages: IPageData[] = [
    {
      pageName: '',
      pageData: {
        title: '',
        content: '',
        puffar: [{ title: '', link: '', color: '', image: '', shadow: false }],
      },
    },
  ]

  @action
  getMainData = async () => {
    const resPage = await api.get(`/pages/${pages.main}?_embed`)
    const resPuffar = await api.get(`/puff`)

    this.pages.push({
      pageName: 'main',
      pageData: {
        title: resPage.data.title.rendered,
        content: resPage.data.content.rendered,
        puffar: resPuffar.data.map((puff: any) => {
          return {
            title: puff.title.rendered,
            content: puff.content.rendered,
            image: puff.acf.image,
            link: puff.slug,
            color: puff.acf.color,
            shadow: puff.acf.shadow,
          }
        }),
      },
    })

    return true
  }

  @action
  getAboutData = async () => {
    const resPage = await api.get(`/pages/${pages.about}?_embed`)
    const resStaff = await api.get(`/staff`)

    this.pages.push({
      pageName: 'about',
      pageData: {
        title: resPage.data.title.rendered,
        content: resPage.data.content.rendered,
        ingress: resPage.data.acf.ingress,
        headercolor: resPage.data.acf.header_textcolor,
        sections: resPage.data.acf.sections.map((item: any) => {
          return {
            title: item.post_title,
            content: item.post_content,
            image: item.acf.image.url,
            imageposition: item.acf.image_alignment_mobile,
            anchor: item.acf.anchor,
          }
        }),
        staff: resStaff.data.map((employee: any) => {
          return {
            id: employee.id,
            title: employee.title.rendered,
            email: employee.acf.email,
            phone: employee.acf.phone,
            image: employee.acf.image,
          }
        }),
      },
    })
  }

  @action
  getProjectsData = async () => {
    const resPage = await api.get(`/pages/${pages.projects}?_embed`)
    const resProjects = await api.get(`/projects?_embed`)

    this.pages.push({
      pageName: 'projects',
      pageData: {
        title: resPage.data.title.rendered,
        content: resPage.data.content.rendered,
        image: resPage.data.featured_image_url,
        headercolor: resPage.data.acf.header_textcolor,
        projects: resProjects.data.map((project: any) => {
          return {
            id: project.id,
            title: project.title.rendered,
            content: project.content.rendered,
            image:
              project._embedded &&
              project._embedded['wp:featuredmedia'][0].source_url,
          }
        }),
      },
    })
  }

  @action
  getRentalData = async () => {
    const resPage = await api.get(`/pages/${pages.rental}?_embed`)
    const resMachineCategories = await api.get(`/machinecategories?_embed`)

    this.pages.push({
      pageName: 'rental',
      pageData: {
        title: resPage.data.title.rendered,
        content: resPage.data.content.rendered,
        headercolor: resPage.data.acf.header_textcolor,
        categories: resMachineCategories.data.map((item: any) => {
          return {
            title: item.title.rendered,
            content: item.content.rendered,
            image:
              item._embedded &&
              item._embedded['wp:featuredmedia'][0].source_url,
            parent: item.acf.parent,
          }
        }),
      },
    })
  }

  @action
  getServicesData = async () => {
    const resPage = await api.get(`/pages/${pages.services}?_embed`)

    this.pages.push({
      pageName: 'services',
      pageData: {
        title: resPage.data.title.rendered,
        content: resPage.data.content.rendered,
        headercolor: resPage.data.acf.header_textcolor,
        sections: resPage.data.acf.sections.map((item: any) => {
          return {
            title: item.post_title,
            content: item.post_content,
            image: item.acf.image.url,
          }
        }),
      },
    })
  }

  @action
  getWorkData = async () => {
    const resPage = await api.get(`/pages/${pages.work}?_embed`)

    this.pages.push({
      pageName: 'work',
      pageData: {
        title: resPage.data.title.rendered,
        content: resPage.data.content.rendered,
        image: resPage.data.featured_image_url,
        headercolor: resPage.data.acf.header_textcolor,
        sections: resPage.data.acf.sections.map((item: any) => {
          return {
            title: item.post_title,
            content: item.post_content,
            image: item.acf.image.url,
          }
        }),
      },
    })
  }

  @action
  getPageData = (page: string) => {
    const result = this.pages.find(obj => {
      return obj.pageName === page
    })

    return result?.pageData
  }

  @action
  getData = async () => {
    await this.getMainData()
    this.isLoading.main = false
    await this.getServicesData()
    this.isLoading.services = false
    await this.getRentalData()
    this.isLoading.rental = false
    await this.getAboutData()
    this.isLoading.about = false
    await this.getProjectsData()
    this.isLoading.projects = false
    await this.getWorkData()
    this.isLoading.work = false
  }
}
