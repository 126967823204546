import Hero from '../components/Hero'
import React from 'react'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'
import { observer } from 'mobx-react'
import { Link } from 'react-router-dom'

import { MainHolder, MainTextHolder, Loading } from '../components/Utils'
import { ApiStore } from '../stores/apiStore'

export const Index = ({ api }: { api: ApiStore }) => (
  <>
    <Hero />
    <MainIndex api={api} />
  </>
)

const MainIndex = observer(({ api }: { api: ApiStore }) => {
  const pageData = api.getPageData('main')

  pageData?.puffar?.map(puff => {
    new Image().src = puff.image
  })

  return (
    <>
      {!api.isLoading.main && (
        <MainHolder
          animate={{ opacity: 1, y: 0 }}
          initial={{ y: 6, opacity: 0 }}
          transition={{ duration: 0.8 }}
          className="mainHolder"
        >
          <MainTextHolder>
            <h4 style={{ marginTop: 16 }}>{pageData?.title}</h4>
            <div
              dangerouslySetInnerHTML={{
                __html: pageData?.content,
              }}
            />
          </MainTextHolder>
          <PuffHolder>
            {pageData?.puffar?.map((puff, index) => {
              return (
                <React.Fragment key={uuid()}>
                  <Puff
                    to={puff.link}
                    key={index}
                    background={puff.image}
                    color={puff.color}
                    shadow={puff.shadow}
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    {puff.color === '#ffffff' && <PuffOverlay />}
                    <span style={{ zIndex: 1 }}>{puff.title}</span>
                  </Puff>
                </React.Fragment>
              )
            })}
          </PuffHolder>
        </MainHolder>
      )}
      {api.isLoading.main && <Loading />}
    </>
  )
})

const PuffHolder = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 769px) {
    max-width: 980px;
    flex-direction: row;
    flex-flow: wrap;
    margin-bottom: -10px;
  }
`

const Puff = styled(Link)`
  height: 220px;
  width: calc(100% - 44px);
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: ${(props: any) =>
    'url(' + props.background + ')' || 'none'};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  color: ${(props: any) => props.color || '#000'};
  text-shadow: ${(props: any) =>
    props.shadow !== false ? '-1px 3px 4px rgba(0,0,0,0.50)' : ''};
  font-size: 17px;
  font-weight: 700;
  text-transform: uppercase;
  margin-bottom: 22px;
  position: relative;
  text-decoration: none;

  @media (max-width: 768px) {
    &:last-child {
      margin-bottom: 0;
    }
  }

  > span {
    font-weight: inherit;
    letter-spacing: 0.7px;
  }

  @media (min-width: 769px) {
    height: 300px;
    width: 46%;
    margin: 10px;
    cursor: pointer;
  }
`

const PuffOverlay = styled.div`
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.45) 49%,
    rgba(0, 0, 0, 0) 100%
  );
`
