import React from 'react'
import MediaQuery from 'react-responsive'
import {
  MainTextHolder,
  MainHolder,
  Loading,
  SubPageHero,
  WideHolder,
} from '../components/Utils'
import { observer } from 'mobx-react'
import { ApiStore } from '../stores/apiStore'
import { AnimatePresence, motion } from 'framer-motion'
import styled from 'styled-components'
import { v4 as uuid } from 'uuid'

import header_work from '../img/header_work.jpg'

export const Work = observer(({ api }: { api: ApiStore }) => {
  const pageData = api.getPageData('work')

  return (
    <>
      <AnimatePresence>
        {!api.isLoading.work && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <MediaQuery minDeviceWidth={769}>
              <SubPageHero
                bgImage={header_work}
                title={pageData?.title}
                color={pageData?.headercolor}
              ></SubPageHero>
            </MediaQuery>
            <MainHolder>
              <MainTextHolder>
                <MediaQuery maxDeviceWidth={768}>
                  <img
                    src={pageData?.image}
                    alt={pageData?.title}
                    style={{ marginBottom: 20 }}
                  />
                </MediaQuery>
                <div dangerouslySetInnerHTML={{ __html: pageData?.content }} />
              </MainTextHolder>
            </MainHolder>
            <WideHolder>
              <SectionList>
                {pageData?.sections?.map((section: any) => (
                  <li key={uuid()}>
                    <img src={section.image} alt={section.title} />
                    <h5>{section.title}</h5>
                    <div
                      dangerouslySetInnerHTML={{ __html: section.content }}
                    />
                  </li>
                ))}
              </SectionList>
            </WideHolder>
            <MainHolder style={{ marginTop: '10px' }}>
              <MainTextHolder>
                Vi ser fram emot din ansökan!
                <br />
                <a href="mailto:jobba@suab.nu">jobba@suab.nu</a>
              </MainTextHolder>
            </MainHolder>
          </motion.div>
        )}
        {api.isLoading.main && <Loading />}
      </AnimatePresence>
    </>
  )
})

const SectionList = styled.ul`
  list-style: none;
  margin: 0 auto;
  width: calc(100% - 40px);
  margin-top: 40px;

  li {
    text-align: center;

    img {
      width: 100%;
      margin-bottom: 10px;
    }

    p {
      margin-top: 0;
    }
  }

  @media (min-width: 769px) {
    display: flex;
    margin: 40px 0 22px;
    width: 100%;

    li {
      text-align: center;
      flex-basis: 33%;

      img {
        width: 100%;
        margin-bottom: 8px;
      }

      p {
        width: 85%;
        display: inline-block;
        margin: 0 auto;
      }
    }

    li:nth-child(2) {
      margin: 0 22px;
    }
  }
`
