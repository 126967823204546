import React from 'react'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'
import {
  MainTextHolder,
  MainHolder,
  StyledImage,
  Loading,
  SubPageHero,
} from '../components/Utils'
import { v4 as uuid } from 'uuid'
import { motion, AnimatePresence } from 'framer-motion'
import { observer } from 'mobx-react'
import { ApiStore } from '../stores/apiStore'

import water_about from '../img/water_about.jpg'

export const About = observer(({ api }: { api: ApiStore }) => {
  const pageData = api.getPageData('about')

  pageData?.staff?.map(employee => {
    new Image().src = employee.image
  })

  return (
    <AnimatePresence>
      {!api.isLoading.about && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 1 }}
        >
          <MediaQuery minDeviceWidth={769}>
            <SubPageHero
              bgImage={water_about}
              title={pageData?.title}
              color={pageData?.headercolor}
            >
              {pageData?.content}
            </SubPageHero>
          </MediaQuery>
          <MainHolder>
            {pageData?.ingress !== '' && (
              <MainTextHolder>
                <div dangerouslySetInnerHTML={{ __html: pageData?.ingress }} />
              </MainTextHolder>
            )}
            <MainTextHolder>
              <SectionList>
                {pageData?.sections?.map((section: any) => (
                  <li
                    key={uuid()}
                    className={section.imageposition}
                    id={section.anchor}
                  >
                    <div
                      dangerouslySetInnerHTML={{ __html: section.content }}
                    />
                  </li>
                ))}
              </SectionList>
              <h4 id="contact">Kontakta oss</h4>
              <p>
                Välkommen att kontakta någon av våra platschefer och
                arbetsledare
              </p>
              <EmployeeList>
                {pageData?.staff?.map((employee: any) => (
                  <Employee key={employee.id}>
                    {employee.image && <StyledImage src={employee.image} />}
                    <h5>{employee.title}</h5>
                    <a href={`mailto:${employee.email}`}>{employee.email}</a>
                    <span>{employee.phone}</span>
                  </Employee>
                ))}
              </EmployeeList>
            </MainTextHolder>
          </MainHolder>
          <MapHolder>
            <iframe
              width="100%"
              height="100%"
              title="Svenska Undervattensentreprenader AB"
              style={{ border: 0 }}
              src="https://www.google.com/maps/embed/v1/place?q=place_id:EilCYWNrYXbDpGdlbiA0LCA2NDUgNDcgU3Ryw6RuZ27DpHMsIFN3ZWRlbiIwEi4KFAoSCXX96w-u_V5GEUJp3eXV-0D5EAQqFAoSCSnmV3uu_V5GEb37pRCA0C7u&key=AIzaSyBZmGHlJ9m898yTIV0PCG0l8c2mdP8yFQg"
            />
          </MapHolder>
        </motion.div>
      )}
      {api.isLoading.main && <Loading />}
    </AnimatePresence>
  )
})

const SectionList = styled.ul`
  list-style: none;
  margin-bottom: 40px;

  li {
    width: 100%;
    margin-bottom: 40px;

    p {
      strong {
        margin-bottom: 4px;
      }
    }

    @media (max-width: 768px) {
      &.bottom {
        .wp-block-columns {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }
  }

  @media (min-width: 769px) {
    display: flex;
    flex-direction: column;

    li {
      text-align: left;
      margin-bottom: 40px;

      :last-child {
        margin-bottom: 0;
      }

      .wp-block-columns {
        display: flex;
        align-items: flex-start;
      }

      .wp-block-column {
        width: calc(50% - 24px);

        &:first-child {
          margin-right: 24px;
        }

        &:last-child {
          margin-left: 24px;
        }
      }

      img {
        width: 100%;
        margin-bottom: 24px;
      }

      p {
        display: inline-block;
        margin: 0 auto 24px;
      }
    }
  }
`

const EmployeeList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: -24px;

  @media (min-width: 769px) {
    max-width: 980px;
    flex-direction: row;
    flex-flow: wrap;
  }
`

const Employee = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-image: ${(props: any) =>
    'url(' + props.background + ')' || 'none'};
  background-size: cover;
  background-position: center center;
  color: ${(props: any) => props.color || '#000'};
  text-shadow: ${(props: any) =>
    props.color === '#fff' ? '-1px 3px 4px rgba(0,0,0,0.50)' : ''};
  font-size: 17px;
  font-weight: 700;
  margin-bottom: 24px;
  position: relative;

  img {
    margin-bottom: 16px;
  }

  h5 {
    margin-bottom: 0;
  }

  @media (min-width: 769px) {
    width: calc(50% - 24px);

    &:nth-child(odd) {
      margin-right: 24px;
    }

    &:nth-child(even) {
      margin-left: 24px;
    }
  }
`

const MapHolder = styled.div`
  margin-top: 80px;
  margin-bottom: -80px;
  width: 100%;
  height: 500px;
`
