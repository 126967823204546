import { observable, action } from 'mobx'

class uiStore {
  @observable menuOpen = false
  @observable subMenuOpen = false
  @observable activePage = ''

  @action
  setMenuOpen = (state: boolean) => {
    this.menuOpen = state
  }

  @action
  setSubMenuOpen = (state: boolean) => {
    this.subMenuOpen = state
  }

  @action
  setActivePage = (state: string) => {
    this.activePage = state
  }
}

export const UIStore = new uiStore()
