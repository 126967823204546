import React from 'react'
import styled from 'styled-components'

export default function Footer() {
  return (
    <FooterDiv>
      <p>
        <Uppercase>Svenska Undervattensentreprenader AB</Uppercase>
        Backavägen 4A &nbsp;645 47 Strängnäs
      </p>
    </FooterDiv>
  )
}

const FooterDiv = styled.div`
  background-color: #172733;
  width: 100%;
  padding: 16px 0 140px;
  box-sizing: border-box;
  color: #fff;
  text-align: center;
  font-size: 11px;
  margin-top: 80px;

  p {
    margin-bottom: 0;
  }
`

const Uppercase = styled.span`
  text-transform: uppercase;
  display: block;
  letter-spacing: 0.147em;
`
