import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import smoothscroll from 'smoothscroll-polyfill'

import Header from './components/Header'
import Footer from './components/Footer'
import { About } from './views/about'
import { Index } from './views/index'
import { Services } from './views/services'
import { Rental } from './views/rental'
import { Projects } from './views/projects'
import { Work } from './views/work'

import { ApiStore } from './stores/apiStore'

const api = new ApiStore()
api.getData()

smoothscroll.polyfill()

export default class App extends React.Component {
  render() {
    return (
      <Router>
        <Header />
        <Switch>
          <Route exact path="/" render={() => <Index api={api} />} />
          <Route exact path="/services" render={() => <Services api={api} />} />
          <Route exact path="/rental" render={() => <Rental api={api} />} />
          <Route exact path="/about" render={() => <About api={api} />} />
          <Route exact path="/projects" render={() => <Projects api={api} />} />
          <Route exact path="/work" render={() => <Work api={api} />} />
        </Switch>
        <Footer />
      </Router>
    )
  }
}
