import React from 'react'
import styled from 'styled-components'
import MediaQuery from 'react-responsive'
import { HashLink as Link } from 'react-router-hash-link'
import { v4 as uuid } from 'uuid'

import { UIStore } from '../stores/UIStore'
import { observer } from 'mobx-react'
import { scrollWithOffset, handleNavigation } from './Utils'

type MenuItemProps = {
  menuItems: [
    {
      title: string
      link: string
      children: [{ title: string; link: string }]
    }
  ]
}

let subMenuTimeout = 0

@observer
export default class MenuItems extends React.Component<MenuItemProps> {
  static defaultProps = {
    menuItems: [
      { title: 'Våra tjänster', link: 'services', children: [] },
      { title: 'Maskinuthyrning', link: 'rental', children: [] },
      {
        title: 'Om oss',
        link: 'about',
        children: [
          { title: 'Vår historia', link: 'about#history' },
          { title: 'Vår miljöpolicy', link: 'about#environment' },
          { title: 'Kontakta oss', link: 'about#contact' },
        ],
      },
      { title: 'Referensprojekt', link: 'projects', children: [] },
      { title: 'Jobba hos oss', link: 'work', children: [] },
    ],
  }

  render() {
    return (
      <ul>
        {this.props.menuItems.map(page => {
          return (
            <React.Fragment key={uuid()}>
              <StyledLi
                key={uuid()}
                onMouseOver={() => {
                  if (page.children.length > 0) {
                    UIStore.setSubMenuOpen(true)
                  }
                }}
                onMouseOut={() => {
                  subMenuTimeout = window.setTimeout(() => {
                    UIStore.setSubMenuOpen(false)
                  }, 400)
                }}
                isActivePage={page.link === UIStore.activePage}
              >
                <Link
                  to={`/${page.link}`}
                  onClick={() => handleNavigation(page.link)}
                >
                  {page.title}
                </Link>
              </StyledLi>
              {page.children.length > 0 && (
                <>
                  <MediaQuery maxDeviceWidth={768} key={uuid()}>
                    {page.children.map(subpage => (
                      <StyledSubLi key={uuid()}>
                        <Link
                          to={`/${subpage.link}`}
                          scroll={el => scrollWithOffset(el, 60)}
                          onClick={() => handleNavigation(page.link)}
                        >
                          {subpage.title}
                        </Link>
                      </StyledSubLi>
                    ))}
                  </MediaQuery>
                  <MediaQuery minDeviceWidth={769} keu={uuid()}>
                    <DesktopSubMenu
                      show={UIStore.subMenuOpen}
                      onMouseOver={() => window.clearTimeout(subMenuTimeout)}
                      onMouseOut={() => {
                        subMenuTimeout = window.setTimeout(() => {
                          UIStore.setSubMenuOpen(false)
                        }, 400)
                      }}
                    >
                      {page.children.map(subpage => (
                        <StyledSubLi key={uuid()}>
                          <Link
                            to={`/${subpage.link}`}
                            scroll={el => scrollWithOffset(el, 40, 'auto')}
                            onClick={() => handleNavigation(page.link)}
                          >
                            {subpage.title}
                          </Link>
                        </StyledSubLi>
                      ))}
                    </DesktopSubMenu>
                  </MediaQuery>
                </>
              )}
            </React.Fragment>
          )
        })}
      </ul>
    )
  }
}

const StyledLi = styled.li`
  margin-right: 16px;
  margin-bottom: 16px;
  white-space: nowrap;
  position: relative;

  a {
    font-family: Poppins;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 16px;
    color: #fff;
    letter-spacing: 0.8px;

    @media (min-width: 769px) {
      color: #000;
      font-size: 14px;
      font-weight: 600;
      letter-spacing: 0.46px;

      ${({ isActivePage }: { isActivePage: boolean }) =>
        isActivePage && !UIStore.subMenuOpen
          ? `
    &::after {
      content: '';
      width: 100%;
      height: 3px;
      background-color: #004981;
      position: absolute;
      bottom: 0;
      left: 0;
    }`
          : ``}
    }
  }

  @media (min-width: 769px) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-right: 0;
  }
`

const StyledSubLi = styled(StyledLi)`
  margin-left: 20px;
  margin-bottom: 16px;
  margin-top: -8px;

  a {
    text-transform: none;
  }

  @media (min-width: 769px) {
    margin-bottom: 8px;
  }
`

const DesktopSubMenu = styled.div`
  position: absolute;
  top: 26px;
  background-color: #eee;
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 26px;
  display: ${({ show }: { show: boolean }) => (show ? 'block' : 'none')};

  @media (min-width: 769px) {
    padding: 20px 0 10px 0;
  }

  ${StyledSubLi} {
    a {
      font-weight: 400;
      font-size: 14px;
    }
  }
`
