import React, { useEffect } from 'react'
import styled from 'styled-components'
import { motion, useAnimation } from 'framer-motion'
import { isMobile } from 'react-device-detect'
import { v4 as uuid } from 'uuid'

import start_desktop from '../img/start_desktop.jpg'
import scuba from '../img/scuba.jpg'

export default function Hero() {
  const controls = useAnimation()

  useEffect(() => {
    controls.start(i => ({
      opacity: 1,
      y: 0,
      transition: { delay: i * 0.3, duration: 0.8 },
    }))
  })

  const herocontents = (
    <MainTitleHolder>
      <motion.ul
        animate={{ opacity: 0 }}
        transition={{ duration: 2, delay: 8 }}
      >
        <MainTitle
          custom={0}
          animate={{ opacity: 1, y: 0 }}
          initial={{ y: 250 }}
          transition={{ duration: 3, delay: 1 }}
          key={uuid()}
        >
          Svenska
          <br />
          Undervattens
          <br />
          entreprenader
        </MainTitle>
      </motion.ul>
    </MainTitleHolder>
  )

  return (
    <>
      {isMobile && (
        <MobileHeroDiv style={{ backgroundImage: `url(${scuba})` }}>
          {herocontents}
          <DownArrow
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -12, rotate: '45deg' }}
            transition={{ delay: 2.5, duration: 1 }}
            onClick={() => {
              let scrollHeight = 0
              const mainElement = document.getElementsByClassName(
                'mainHolder'
              )[0]
              if (mainElement instanceof HTMLElement) {
                scrollHeight = mainElement.offsetTop
              }
              window.scroll({
                top: scrollHeight - 58,
                left: 0,
                behavior: 'smooth',
              })
            }}
          />
        </MobileHeroDiv>
      )}
      {!isMobile && (
        <HeroDiv style={{ backgroundImage: `url(${start_desktop})` }}>
          {herocontents}
          <DownArrow
            animate={{ opacity: 1, y: 0 }}
            initial={{ opacity: 0, y: -12, rotate: '45deg' }}
            transition={{ delay: 2.5, duration: 1 }}
            onClick={() => {
              window.scroll({
                top: 680,
                left: 0,
                behavior: 'smooth',
              })
            }}
          />
        </HeroDiv>
      )}
    </>
  )
}

const MobileHeroDiv = styled.div`
  width: 100vw;
  height: 100.1vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  filter: grayscale();
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: -60px;
`

const HeroDiv = styled.div`
  height: 600px;
  margin-top: 80px;
  width: 100vw;
  filter: grayscale();
  display: flex;
  align-items: center;
  justify-content: center;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;

  @media (min-width: 769px) {
    margin-top: 0;
    margin-bottom: 14px;
  }

  > img {
    margin-top: 10%;
  }
`

const MainTitleHolder = styled.div`
  background-image: linear-gradient(
    -180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.45) 49%,
    rgba(0, 0, 0, 0) 100%
  );
  width: 100vw;
  overflow-y: hidden;
  height: 140px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 769px) {
    height: 270px;
  }
`

const MainTitle = styled(motion.li)`
  font-family: Poppins;
  font-weight: 300;
  color: #fff;
  text-align: center;
  opacity: 0;
  font-size: 40px;
  line-height: 42px;

  @media (min-width: 769px) {
    font-size: 72px;
    line-height: 70px;
    font-weight: 200;
  }
`

const DownArrow = styled(motion.div)`
  display: block;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 30px;
  left: calc(50% - 15px);
  border: 2px solid #fff;
  border-top: 0;
  border-left: 0;
`
