import React from 'react'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'

import { Loading, SubPageHero, WideHolder } from '../components/Utils'
import { ApiStore } from '../stores/apiStore'
import { observer } from 'mobx-react'
import { AnimatePresence } from 'framer-motion'

import water_rental from '../img/water_rental.jpg'

export const Services = observer(({ api }: { api: ApiStore }) => {
  const pageData = api.getPageData('services')
  return (
    <>
      <AnimatePresence>
        {!api.isLoading.services && (
          <>
            <MediaQuery minDeviceWidth={769}>
              <SubPageHero
                bgImage={water_rental}
                title={pageData?.title}
                color={pageData?.headercolor}
              >
                {pageData?.content}
              </SubPageHero>
            </MediaQuery>
            <WideHolder>
              <SectionList>
                {pageData?.sections?.map((section: any) => (
                  <li>
                    <img src={section.image} alt={section.title} />
                    <h5>{section.title}</h5>
                    <div
                      dangerouslySetInnerHTML={{ __html: section.content }}
                    />
                  </li>
                ))}
              </SectionList>
            </WideHolder>
          </>
        )}
        {api.isLoading.main && <Loading />}
      </AnimatePresence>
    </>
  )
})

const SectionList = styled.ul`
  list-style: none;
  margin: 0 20px;

  li {
    text-align: center;

    &:first-child {
      img {
        margin: 0 auto 10px;
      }
    }

    img {
      width: 100%;
      margin: 40px auto 10px;
    }

    p {
      margin-top: 0;
    }
  }

  @media (min-width: 769px) {
    display: flex;
    margin: 22px 0 0;

    li {
      flex-basis: 33%;

      &:first-child {
        img {
          margin: 0 auto 18px;
        }
      }

      img {
        margin-bottom: 18px;
        margin-top: 0;
      }

      p {
        width: 85%;
        display: inline-block;
        margin: 0 auto;
      }
    }

    li:nth-child(2) {
      margin: 0 22px;
    }
  }
`
