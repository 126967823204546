import React from 'react'
import MediaQuery from 'react-responsive'
import styled from 'styled-components'

import {
  MainTextHolder,
  MainHolder,
  Loading,
  SubPageHero,
} from '../components/Utils'
import { ApiStore } from '../stores/apiStore'
import { observer } from 'mobx-react'
import { AnimatePresence, motion } from 'framer-motion'

import water_projects from '../img/water_projects.jpg'

export const Projects = observer(({ api }: { api: ApiStore }) => {
  const pageData = api.getPageData('projects')

  return (
    <>
      <AnimatePresence>
        {!api.isLoading.projects && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 1 }}
          >
            <MediaQuery minDeviceWidth={769}>
              <SubPageHero
                bgImage={water_projects}
                title={pageData?.title}
                color={pageData?.headercolor}
              >
                {pageData?.content}
              </SubPageHero>
            </MediaQuery>
            <MainHolder>
              <MainTextHolder>
                <ProjectsList>
                  {pageData?.projects?.map((project: any) => (
                    <li>
                      {project.image ? (
                        <ProjectCols>
                          <img src={project.image} alt="" />
                          <div>
                            <h5>{project.title}</h5>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: project.content,
                              }}
                            />
                          </div>
                        </ProjectCols>
                      ) : (
                        <>
                          <h5>{project.title}</h5>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: project.content,
                            }}
                          />
                        </>
                      )}
                    </li>
                  ))}
                </ProjectsList>
              </MainTextHolder>
            </MainHolder>
          </motion.div>
        )}
        {api.isLoading.main && <Loading />}
      </AnimatePresence>
    </>
  )
})

const ProjectCols = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  margin-bottom: -20px;

  img {
    width: 100%;
    margin-bottom: 20px;
  }

  @media (min-width: 769px) {
    flex-direction: row;
    margin-bottom: 20px;

    img {
      width: 50%;
      margin-bottom: 0;
    }

    > div {
      width: calc(50% - 40px);
      text-align: left;
      margin-left: 40px;
    }
  }
`

const ProjectsList = styled.ul`
  list-style: none;

  li {
    width: 100%;
    margin-bottom: 40px;

    &:last-child {
      margin-bottom: 0;
    }

    strong {
      text-transform: uppercase;
    }
  }

  @media (min-width: 769px) {
    display: flex;
    flex-direction: column;

    li {
      text-align: center;
      margin-bottom: 30px;

      p {
        margin-bottom: 0;
      }
    }
  }
`
